
import state from './moduleCallStatusState.js'
import mutations from './moduleCallStatusMutations.js'
import actions from './moduleCallStatusActions.js'
import getters from './moduleCallStatusGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
