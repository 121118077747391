export default {
  allLeads: [],
  spacificLead: {},
  leadFrom: [],
  getSpacificFrom: {},
  getInfoToCreateLead: {},
  getSpacificshift_times: {},
  getAllshift_times: [],
  getAllPackageOrService: []
}
