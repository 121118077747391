
export default {
  /* getAllLeads (state, payload) {
    state.allLeads = payload
  } */
  getAllMembers (state, data) {
    state.allMembers = data
  },
  getMemberPackagesOrMemberServices (state, data) {
    state.allMemberPackagesOrMemberServices = data
  }
}
