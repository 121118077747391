import Api from '@/axios'

export default {
  getAllUser (pageNumber, filterTitle, filterStatus) {
    return Api().get(`users?page=${pageNumber}&name=${filterTitle}&status=${filterStatus}`)
  },
  getSpacificUser (userId) {
    return Api().get(`users/${userId}`)
  },
  createNewUser (userData) {
    return Api().post('auth/register', userData)
  },
  deleteUser (userId) {
    return Api().delete(`users/${userId}`)
  },
  editUser (userId, newData) {
    console.log(userId)
    return Api().post(`/users/${userId}`, newData)
  },
  changeUserStatus (userId) {
    return Api().patch(`/users/status/${userId}`)
  },
  resetUser (userId) {
    return Api().patch(`/users/reset/role/${userId}`)
  },
  editCustomRole (userId, data) {
    return Api().post(`/users/custom/role/${userId}`, data)
  }
}
