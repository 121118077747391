
import state from './moduleAccountsState'
import mutations from './moduleAccountsMutations'
import actions from './moduleAccountsActions'
import getters from './moduleAccountsGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
