import callCenter from '@/services/CallCenter/callCenter'

export default {
  getNewUserCallData ({ dispatch, commit }, status) {
    console.log(status)
    return new Promise((resolve, reject) => {
      callCenter.getNewUserCallData(status).then(res => {
        commit('getNewUserCallData', res.data)
        dispatch('getUserCallData', 1)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getUserCallData ({ commit }, { pageNumber = 1 }) {
    return new Promise((resolve, reject) => {
      callCenter.getUserCallData(pageNumber).then(res => {
        commit('getUserCallData', res.data.data)
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getSalesCreate () {
    return new Promise((resolve, reject) => {
      callCenter.getSalesCreate().then(res => {
        resolve(res.data.data)
      }).catch(error => {
        reject(error)
      })
    })
  },
  changeStatusCall ({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      callCenter.changeStatusCall(data).then(res => {
        dispatch('getUserCallData', { pageNumber: 1 })
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
