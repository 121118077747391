import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
// import './config/firebase'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'

import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import 'vue-select/src/scss/vue-select.scss'
import './Helpers/permisiionHelper'
import authPermissions from './mixins/auth-permission'
import memberMenu from './components/members/memberMenu'
global.Raphael = Raphael
Vue.use(AlgoliaComponents)
Vue.component('multiselect', Multiselect)
Vue.component('v-select', vSelect)
Vue.component('member-menu', memberMenu)
Vue.mixin(authPermissions)
Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
