import Api from '@/axios'

export default {
  getAllInvitations (pageNumber, memberId) {
    return Api().get(`memberships/invitations?member_id=${memberId}&page=${pageNumber}`)
  },
  getAllPayments (pageNumber, memberId) {
    return Api().get(`memberships/payments?member_id=${memberId}&page=${pageNumber}`)
  },
  getAllVisits (pageNumber, memberId) {
    return Api().get(`memberships/visits?member_id=${memberId}&page=${pageNumber}`)
  },
  getAllDiscounts (pageNumber, memberId, status) {
    return Api().get(`memberships/discounts?member_id=${memberId}&page=${pageNumber}&status=${status}`)
  },
  approveDiscount (id) {
    return Api().patch(`memberships/discounts/${id}`)
  }
}
