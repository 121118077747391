export default {
  // isUserLoggedIn: () => {
  //     return (localStorage.getItem('accessToken') ? true : false);
  // },
  // isUserFirstTime: () => {
  //     return localStorage.getItem('isFirst')
  // },
  // isRoleEmpolyee: () => {
  //     let check = localStorage.getItem('Roles').indexOf('Employee') > -1
  //     return check
  // },
  tokenInfo: {},
  allowMessage: ''
}
