export default {
  getAlldepartments: state => {
    return state.alldepartments
  },
  getSpacificdepartments: state => {
    return state.spacificdepartments
  },
  getUserDepartments: state => {
    return state.getUserDepartments
  }
}
