export default {
  GetSpacificshift_times: state => {
    return state.getSpacificshift_times
  },
  GetAllshift_times: state => {
    return state.getAllshift_times
  },
  getAllPackageOrService: state => {
    return state.getAllPackageOrService
  }
}
