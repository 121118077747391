
import state from './moduleRolesState.js'
import mutations from './moduleRolesMutations.js'
import actions from './moduleRolesActions.js'
import getters from './moduleRolesGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
