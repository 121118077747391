import Api from '@/axios'

export default {
  getCreateMemberShip () {
    return Api().get('memberships/create')
  },
  serachByPhone (phone) {
    return Api().get(`memberships/search-by-phone?phone=${phone}`)
  },
  storeUser (userData) {
    return Api().post('memberships/store', userData)
  },
  cretaeMembership (userId, data) {
    return Api().post(`memberships/assign/packages/${userId}`, data)
  },
  getAllMembers (pageNumber, filterTitle) {
    return Api().get(`/memberships?page=${pageNumber}&memberId=${filterTitle}`)
  },
  uploadMemberImage (userId, image) {
    return Api().post(`memberships/profile/upload/${userId}`, image)
  },
  getMembership (userId) {
    return Api().get(`memberships/${userId}`)
  },
  searchByMemberID (memberId) {
    return Api().get(`memberships/search-by-member-id/${memberId}`)
  },
  checkInPackages (id, data) {
    return Api().post(`memberships/visits/package/${id}`, { data: data })
  },
  checkInServices (id, servicesIds) {
    return Api().post(`memberships/visits/service/${id}`, { services_ids: servicesIds })
  },
  // member profile
  getMemberPackagesOrMemberServices (id, type) {
    return Api().get(`memberships/sales/${id}?type=${type}`)
  },
  // pay Remaining
  payRemaining (memberId, packageId, data) {
    return Api().post(`memberships/payments/store/${memberId}/${packageId}`, data)
  },
  // invitation
  inviteFriend (memberId, data) {
    return Api().post(`memberships/invitations/store/${memberId}`, data)
  },
  // transfers
  transferPackage (memberId, data) {
    return Api().post(`memberships/transfers/store/${memberId}`, data)
  },

  getAvailablePackageToUpgrade (packageId) {
    return Api().get(`memberships/upgrades/create/${packageId}`)
  },
  upgradePackage (memberId, data) {
    return Api().post(`memberships/upgrades/store/${memberId}`, data)
  }
}
