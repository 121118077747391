export default {
  getAllLeads: state => {
    return state.allLeads
  },
  getSpacificLead: state => {
    return state.spacificLead
  },
  getLeadFrom: state => {
    return state.leadFrom
  },
  getSpacificFrom: state => {
    return state.getSpacificFrom
  },
  getInfoToCreateLead: state => {
    return state.getInfoToCreateLead
  }
}
