export default {
  getUserProjects: state => {
    return state.userProjects
  },
  getAllProject: state => {
    return state.allProjects
  },
  getSpacificProject: state => {
    return state.spacificProject
  }
}
