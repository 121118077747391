
import state from './moduleDepartmentsState.js'
import mutations from './moduleDepartmentsMutations.js'
import actions from './moduleDepartmentsActions.js'
import getters from './moduleDepartmentsGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
