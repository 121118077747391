
import state from './modulePermissionsState.js'
import mutations from './modulePermissionsMutations.js'
import getters from './modulePermissionsGetters.js'

export default {
  state: state,
  mutations: mutations,
  getters: getters
}
