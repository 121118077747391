
import state from './moduleMemberShipState.js'
import mutations from './moduleMemberShipMutations.js'
import actions from './moduleMemberShipActions'
import getters from './moduleMemberShipGetters'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
