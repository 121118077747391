
import state from './moduleSourcesState.js'
import mutations from './moduleSourcesMutations.js'
import actions from './moduleSourcesActions.js'
import getters from './moduleSourcesGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
