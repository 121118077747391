
import state from './moduleCallCenterState.js'
import mutations from './moduleCallCenterMutations.js'
import actions from './moduleCallCenterActions.js'
import getters from './moduleCallCenterGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
