import reportServices from '@/services/Reports/reports'

export default {
  getAllInvitations (_, { pageNumber = 1, memberId = '' }) {
    return new Promise((resolve, reject) => {
      reportServices.getAllInvitations(pageNumber, memberId).then(res => {
        resolve(res)
      }).catch(error => {
        console.log(error.response)
        reject(error)
      })
    })
  },
  getAllVisits (_, { pageNumber = 1, memberId = '' }) {
    return new Promise((resolve, reject) => {
      reportServices.getAllVisits(pageNumber, memberId).then(res => {
        resolve(res)
      }).catch(error => {
        console.log(error.response)
        reject(error)
      })
    })
  },
  getAllPayments (_, { pageNumber = 1, memberId = '' }) {
    return new Promise((resolve, reject) => {
      reportServices.getAllPayments(pageNumber, memberId).then(res => {
        resolve(res)
      }).catch(error => {
        console.log(error.response)
        reject(error)
      })
    })
  },
  getAllDiscounts (_, { pageNumber = 1, memberId = '', status = '' }) {
    return new Promise((resolve, reject) => {
      reportServices.getAllDiscounts(pageNumber, memberId, status).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  },
  approveDiscount (_, id) {
    return new Promise((resolve, reject) => {
      reportServices.approveDiscount(id).then(res => {
        resolve(res)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
