export default {
  getUserData: state => {
    return state.userData
  },
  getAllUser: state => {
    return state.allUser
  },
  getSpacificUser: state => {
    return state.spacificUser
  }
}
