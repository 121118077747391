import axios from 'axios'
import router from './router/index'
export default () => {
  const apiClient = axios.create({
    baseURL: 'https://api.alleons.com/api/',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null}`,
      Accept: 'application/json'
    }
  })
  apiClient.interceptors.response.use((response) => {
    return response
  }, (error) => {
    console.log('error response', error.response.data.message)
    // eslint-disable-next-line eqeqeq
    if (error.response.data.message == 'not_allow_ip_address' || error.response.data.message == 'not_allow_ip_address' || error.response.data.message == 'Unauthenticated.') {
      localStorage.removeItem('access_token')
      router.push('/auth/sign-in1')
      localStorage.setItem('errorData', 'you are not in maxevia!')
      localStorage.setItem('errorPhone', 'not_allow_ip_address!')
    }
    return Promise.reject(error)
  })
  return apiClient
}
