
import state from './moduleLeadsState.js'
import mutations from './moduleLeadsMutations.js'
import actions from './moduleLeadsActions.js'
import getters from './moduleLeadsGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
