import Api from '@/axios'

export default {
  getUserCallData (pageNumber) {
    return Api().get(`sales/leads?page=${pageNumber}`)
  },
  getNewUserCallData (status) {
    return Api().post(`sales/reservation/${status}`)
  },
  getSalesCreate () {
    return Api().get('sales/create')
  },
  changeStatusCall (data) {
    return Api().post('sales/change/status', data)
  }
}
