
import state from './moduleInquiriesState.js'
import mutations from './moduleInquiriesMutations.js'
import actions from './moduleInquiriesActions.js'
import getters from './moduleInquiriesGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
