
import state from './moduleEnterprisesState.js'
import mutations from './moduleEnterprisesMutations.js'
import actions from './moduleEnterprisesActions.js'
import getters from './moduleEnterprisesGetters.js'

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
