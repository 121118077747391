<template>
  <router-view/>
</template>
<script>
import { core } from './config/pluginInit'
export default {
  name: 'App',
  components: {
  },
  mounted () {
    core.mainIndex()
  },
  created () {
    /*
    window.addEventListener('unload', this.leaving)
*/
    //  s
  },
  methods: {
    leaving (e) {
      e.preventDefault()
      this.$store.dispatch('logout')
      e.returnValue = ''
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.upperMenu .nav-link.router-link-exact-active.router-link-active {
  border-bottom: 0 !important;
  color: var(--iq-primary) !important;
}
#lottie-beil svg {
  transform: translate3d(0px, -3px, 0px) !important;
}
th, td{
  outline: 0 !important;
  white-space: nowrap;
}
  @import "assets/scss/style.scss";
  .vs--searchable div{
    min-height: 35px !important;
    border-radius: 10px;
  }
  .cursor-pointer{
    cursor: pointer;
  }
  .filter .form-control{
    height: 35px;
    line-height: 45px;
    background: transparent;
    border: 1px solid #d7dbda;
    font-size: 13px;
    color: var(--iq-secondary);
    border-radius: 10px;
  }
  [dir=ltr][mode=dark] .modal-content{
    background-color: #252549 !important;
  }
  [dir=ltr][mode=dark] .iq-bg-success{
    color: #fff !important;
    background: rgba(51,148,74,0.3) !important;
  }
  [dir=ltr][mode=dark] .iq-bg-danger{
    background: rgba(241,67,54,0.3)  !important;
    color: #fff !important;
  }
  ul#__BVID__120__BV_tab_controls_{
    margin: 0 !important;
    padding: 0 !important;
  }
  button.vs__deselect {
    margin-left: 7px !important;
  }
  .holdSelection{
    user-select: none;
  }
  .holdSelection::selection{
    background: none;
  }
  .holdSelection::-moz-selection{
    background: none;
  }
  #sidebar-scrollbar{
    border-top: 1px solid #967af32b;
    padding-top: 15px !important;
  }
table#table-transition-example .flip-list-move {
  transition: transform 1s;
}
.selectedNumber{
  color: var(--iq-white) !important;
  background: linear-gradient(to right, var(--iq-primary) 0%, var(--iq-primary-light) 100%) !important;
}
.periodNumbers {
  padding: 5px;
  background: transparent;
  border: 1px solid #d7dbda;
  font-size: 14px;
  color: var(--iq-secondary);
  border-radius: 10px;
  background: #92e8cb00;
  display: flex;
  align-items: center;
  justify-content: start;
}
.periodNumbers span {
  cursor: pointer;
  background: #ececec;
  padding: 5px 15px;
  border-radius: 10px;
  margin-right: 10px;
  color: #5b5b5b;
}
[dir=ltr][mode=light] .btn i {
  margin-right: 0 !important;
}
.tabs .nav.nav-tabs {
  margin-bottom: 6px !important;
}
[dir=ltr][mode=light] .accordion-details .list-group-item.active, .border-active{
  transition: all 0.3s ease-in-out;
  background-color: #fff !important;
  color: var(--iq-primary) !important;
  border:1px solid var(--iq-primary) !important;
}
[dir=ltr][mode=light] .iq-accordion.career-style.faq-style .iq-accordion-block{
  border-radius: 0 !important;
}
.cursor-crosshair{
  cursor: crosshair;
}
.h-10 {
  height: 5px;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.primary-color {
  background-color: var(--iq-bg-light-color)
}
.custom-rounded {
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}
.white-space {
  white-space: pre-wrap;
}
/*hsl(253deg 61% 55%)*/
</style>
